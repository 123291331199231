import { CircularProgress } from '@mui/material'
import classNames from 'classnames'
import { ButtonComponentProps } from 'ecm-design/interfaces/atoms.interface'
import { StyledButton } from './Button.components'

const Button = ({
  label,
  disabled,
  pending,
  testTag,
  color,
  variant = 'contained',
  fontWeight,
  size = 'medium',
  endIcon,
  onClick,
  type,
  sx
}: ButtonComponentProps): JSX.Element => {
  const buttonClassNames = classNames(
    `button__${color}`,
    `button__${variant}`,
    `button__font-weight-${fontWeight}`,
    `button__${size}`
  )
  const dataTestTag: Record<string, string> = {}

  if (testTag != null) {
    if (typeof testTag === 'string') {
      dataTestTag[`data-test-${testTag}-button`] = 'true'
    } else if (typeof testTag === 'object') {
      dataTestTag[`data-test-${Object.keys(testTag)[0]}-button`] = Object.values(testTag)[0]
    }
  }

  return (
    <StyledButton
      className={buttonClassNames}
      variant={variant}
      disableElevation
      fullWidth
      disabled={disabled}
      {...dataTestTag}
      onClick={onClick}
      endIcon={endIcon}
      type={type}
      sx={sx}
    >
      {pending != null && pending ? <CircularProgress color='inherit' size={20} thickness={5} /> : label}
    </StyledButton>
  )
}
Button.defaultProps = {
  disabled: false,
  testTag: null,
  color: 'mariner',
  fontWeight: 'bold'
}
export default Button
