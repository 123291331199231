import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Box, Dialog, Grid, Typography } from '@mui/material'
import Button from 'ecm-design/components/atoms/buttons/Button'
import lzMobileAppLogo from 'assets/lz_mobile_app_logo.svg'
import googleChromeLogo from 'assets/google_chrome_logo.svg'

const BeforeInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null)
  const prefersDarkMode = window.matchMedia?.('(prefers-color-scheme: dark)').matches

  const handleBeforeInstallPrompt = useCallback((event: Event): void => {
    event.preventDefault()
    setDeferredPrompt(event)
  }, [])

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    }
  }, [])

  const handleInstallClick = (): void => {
    if (deferredPrompt) {
      const promptEvent = deferredPrompt as Event & { prompt: () => Promise<unknown> }
      promptEvent.prompt().finally(promptCancelled)
    }
  }

  const promptCancelled = (): void => {
    setDeferredPrompt(null)
    window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
  }

  if (deferredPrompt && isMobile) {
    return (
      <Dialog
        open
        PaperProps={{
          sx: {
            margin: 0,
            borderRadius: '12px 12px 0 0',
            bgcolor: prefersDarkMode ? 'lzNegroni.neutral.900' : 'white'
          }
        }}
        sx={{ '& .MuiDialog-container': { alignItems: 'flex-end' } }}
      >
        <Grid container justifyContent='center' py='14px' px='16px'>
          <Typography
            fontWeight='600'
            fontSize='18px'
            lineHeight='24px'
            color={prefersDarkMode ? 'white' : 'lzNegroni.neutral2.900'}
          >
            See LZ Virtual Mail in...
          </Typography>
          <Grid
            container
            display='grid'
            alignItems='center'
            gridTemplateColumns='48px 1fr 108px'
            gap='15px 8px'
            mt='24px'
          >
            <img src={lzMobileAppLogo} alt='home' />
            <Typography
              fontWeight='600'
              fontSize='16px'
              lineHeight='24px'
              pl='8px'
              color={prefersDarkMode ? 'white' : 'lzNegroni.neutral2.900'}
            >
              LZ Virtual Mail App
            </Typography>
            <Button
              label='Open'
              onClick={handleInstallClick}
              sx={{ borderRadius: '28px', width: '108px' }}
              size='large'
            />
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              border='1px solid'
              borderColor={prefersDarkMode ? 'lzNegroni.neutral2.800' : 'lzNegroni.neutral2.100'}
              bgcolor={prefersDarkMode ? 'lzNegroni.neutral2.900' : 'white'}
              borderRadius='9px'
              height='48px'
              width='48px'
            >
              <img src={googleChromeLogo} alt='home' />
            </Box>
            <Typography
              fontWeight='600'
              fontSize='16px'
              lineHeight='24px'
              pl='8px'
              color={prefersDarkMode ? 'white' : 'lzNegroni.neutral2.900'}
            >
              Chrome
            </Typography>

            <Button
              label='Continue'
              onClick={promptCancelled}
              sx={{
                borderRadius: '28px',
                width: '108px',
                '&.button__contained.button__mariner': {
                  backgroundColor: prefersDarkMode ? 'lzNegroni.neutral2.800' : 'lzNegroni.neutral2.100',
                  color: prefersDarkMode ? 'white' : 'lzNegroni.neutral2.900'
                },
                '&.button__contained.button__mariner:focus': {
                  backgroundColor: prefersDarkMode ? 'lzNegroni.neutral.700' : 'lzNegroni.neutral2.100'
                },
                '&.button__contained.button__mariner:hover': {
                  backgroundColor: prefersDarkMode ? 'lzNegroni.neutral.700' : 'lzNegroni.neutral2.100'
                }
              }}
              size='large'
            />
          </Grid>
        </Grid>
      </Dialog>
    )
  }
  return null
}

export default BeforeInstallPrompt
