import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '8px 15px',
  fontSize: '13px',
  lineHeight: '20px',
  textTransform: 'none',
  borderRadius: '8px',
  color: 'white',
  '&.button__contained': {
    '&.button__mariner': {
      background: theme.palette.mariner.main,
      '&:hover': {
        background: theme.palette.mariner.dark
      },
      '&.Mui-disabled': {
        background: theme.palette.mariner['light-4'],
        color: theme.palette.mariner['light-2']
      }
    },
    '&.button__greenHaze': {
      background: theme.palette.greenHaze.main,
      '&:hover': {
        background: theme.palette.greenHaze.dark
      },
      '&.Mui-disabled': {
        background: theme.palette.greenHaze['light-4'],
        color: theme.palette.greenHaze['light-2']
      }
    },
    '&.button__greenHaze-light-2': {
      color: theme.palette.astronaut.main,
      background: theme.palette.greenHaze['light-2'],
      '&:hover': {
        background: theme.palette.greenHaze['light-3']
      },
      '&.Mui-disabled': {
        background: theme.palette.greenHaze['light-4'],
        color: theme.palette.astronaut['light-2']
      }
    },
    '&.button__cinnabar': {
      background: theme.palette.cinnabar.main,
      '&:hover': {
        background: theme.palette.cinnabar.dark
      },
      '&.Mui-disabled': {
        background: theme.palette.cinnabar['light-4'],
        color: theme.palette.cinnabar['light-2']
      }
    },
    '&.button__astronaut': {
      color: theme.palette.mariner.main,
      background: theme.palette.mariner['light-5'],
      '&:hover': {
        background: theme.palette.mariner['light-4']
      },
      '&.Mui-disabled': {
        background: theme.palette.mariner['light-5'],
        color: theme.palette.mariner['light-3']
      }
    },
    '&.button__astronaut-dark': {
      background: theme.palette.mariner.main,
      '&:hover': {
        background: theme.palette.mariner.dark
      },
      '&.Mui-disabled': {
        background: theme.palette.mariner['light-4'],
        color: theme.palette.mariner['light-2']
      }
    },
    '&.button__astronaut-light-5': {
      color: theme.palette.mariner.main,
      background: theme.palette.mariner['light-5'],
      '&:hover': {
        background: theme.palette.mariner['light-4']
      },
      '&.Mui-disabled': {
        background: theme.palette.mariner['light-5'],
        color: theme.palette.mariner['light-3']
      }
    }
  },
  '&.button__font-weight-bold': {
    fontWeight: 700
  },
  '&.button__font-weight-normal': {
    fontWeight: 400
  },
  '&.button__text': {
    '&.button__astronaut': {
      color: theme.palette.astronaut['light-2']
    }
  },
  '& .MuiButton-label': {
    whiteSpace: 'nowrap'
  },
  '&.button__large': {
    fontSize: '15px',
    padding: '17px 14px'
  }
}))
